export const datapointExamples = [
  {
    name: "Public Datapoint",
    markdown: `{% inlineRouterLink articleId="create-a-datapoint" %}Public datapoints{% /inlineRouterLink %} are accessible by all users. They can be free or paid.

    ![Public datapoint menu open screenshot](https://assets.towerhunt.com/site/publicDatapointMenuOpen0.png)`,
  },
  {
    name: "Safezone Datapoint",
    markdown: `{% inlineRouterLink articleId="create-a-datapoint" %}Safezone datapoints{% /inlineRouterLink %} are private.

    ![Safezone datapoint menu open screenshot](https://assets.towerhunt.com/site/safezoneDatapointMenuOpen0.png)`,
  },
  {
    name: "Calculation Datapoint",
    markdown: `{% inlineRouterLink articleId="perform-calculations-on-datapoints" %}Calculation datapoints{% /inlineRouterLink %} produce output values via calculations performed on collections of other datapoints.

    ![Calculation datapoint menu open screenshot](https://assets.towerhunt.com/site/calculationDatapointMenuOpen0.png)`,
  },
];

export const whatIsADataPoint = {
  order: 0,
  name: "What is a datapoint?",
  subcategory: "Intro to datapoints",
  markdown: `# What is a datapoint?

  Datapoints are the basic building blocks of Tower Hunt. More than just pieces of information, Tower Hunt datapoints are self-contained systems with specialized functions and internal behaviors. A datapoint's primary job is to facilitate access to the real estate information you need. But datapoints also enable other key benefits such as revenue sharing, data privacy, and real-time editing. Look at a few examples below, and then read on to learn more about all of the ways datapoints enable more freedom and upside for you and your teams.

  {% tabbedContainer tabs=$datapointExamples marginStyles="mb-4" /%}

  {% callout type="tip" %}
  **Tip:** Learn more about the {% inlineRouterLink articleId="understand-datapoint-colors" %}visual features{% /inlineRouterLink %} and {% inlineRouterLink articleId="datapoint-interactions" %}interactions{% /inlineRouterLink %} of datapoints to maximize your productivity.
  {% /callout %}

  ## Tower Hunt datapoints vs other databases

  Using Tower Hunt is meant to feel familiar to people who have worked with other real estate databases. However, Tower Hunt datapoints allow you to do things that you can't do in other databases. Here are a few key differences:

  - {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}**Pay-as-you-go pricing**{% /inlineRouterLink %}: In Tower Hunt, you pay for the datapoints that you access rather than via a fixed fee per user. This provides the best alignment between the product, its users, and overall market conditions. Tower Hunt datapoints make this possible.
  - **Dynamic pricing**: Tower Hunt datapoints are {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" sectionName="How much does it cost" %}graded according to four criteria: recency, difficulty, sourcing, and credibility{% /inlineRouterLink %}. This benefits users because it links the cost of data to its real-world value, taking into account the types of imperfections that are inherent in commercial real estate data. It also benefits contributors because the datapoints that matter most also cost the most.
  - **Precision unlocking**: Tower Hunt gives you the freedom to unlock the specific datapoints that you need. Datapoints can be unlocked in batches (ex. an entire property survey, an individual property, an individual deal) or individually (ex. a single price, square footage, or involved company). This means you can try Tower Hunt for almost nothing and then increase your investment based on how good of a job Tower Hunt does.
  - **Proof of knowledge**: Many {% inlineRouterLink articleId="understand-datapoint-colors" %}unlockable datapoints{% /inlineRouterLink %} offer an option to {% inlineRouterLink articleId="unlock-what-you-already-know" %}prove you already know the underlying data{% /inlineRouterLink %}. This is a great way to start contributing (and earning!) when you have knowledge of specific properties and investments.
  - **Public and private**: Like other databases, Tower Hunt contains real estate data that any user can access. Tower Hunt also lets you augment this public layer with Safezone datapoints that reflect your unique view of the market.
  - **Public layer editing**: How many times have you been frustrated by inaccurate and/or incomplete information in another database? This is inevitable since commercial real estate has no single source of truth. Tower Hunt makes mistakes, too, but the critical difference is that you can fix them yourself and start using that improved data right away.
  - **Revenue sharing**: Every public datapoint generates economic value. Datapoints that are offered "free" produce usage credits. Paid datapoints produce revenue. Tower Hunt shares this economic value with contributors and validators, providing a net new income stream to market participants.
  - **Safezone sharing**: Acquiring and trading Safezone (non-public) data is a crucial real-world activity for commercial real estate professionals. Tower Hunt provides a digital companion for these efforts, allowing you to selectively share individual datapoints with other users and teams. For example, you could share the price of a sale but not the buyer's identity.
  - **Calculation**: Certain datapoints produce output values via calculations performed on collections of other datapoints. These so-called {% inlineRouterLink articleId="perform-calculations-on-datapoints" %}*calculation datapoints*{% /inlineRouterLink %} orchestrate many of the concepts described above &mdash; revenue sharing, pay-as-you-go pricing, and public/private access &mdash; to help Tower Hunt deliver value to the data's consumers **and** contributors.
  - **Tasks, notes, and files**: Users can easily link datapoints to private tasks, notes, and files. These are powerful combinations that personalize your experience with Tower Hunt and make raw real estate data more actionable.`,
};
